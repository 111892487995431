#cotiNav {
    background-color: $dark;

    .navbar-toggler {
        color: $white;
        &:focus,
        &:hover {
            background-color: $white;
            color: $dark;
        }
    }

    .navbar-brand {
        img {
            height: 46px;
        }
    }

    .navbar-collapse {
        .navbar-nav {
            >.nav-item {
                >.nav-link {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    color: $white;

                    &:focus,
                    &:hover {
                        color: rgba(255, 255, 255, .5);
                    }

                    &.active,
                    &.active:focus,
                    &.active:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    @media (min-width: 992px) {
        height: auto;
        padding: 30px 0;
        background: linear-gradient(#000, rgba(#000, 0));
        transition: all 300ms;
        .navbar-brand {
            img {
                height: 64px;
                transition: all 300ms;
            }
        }

        .navbar-collapse {
            .navbar-nav {
                >.nav-item {
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }

                    >.nav-link {
                        padding: .75rem 1rem;

                        &.active,
                        &.active:focus,
                        &.active:hover {
                            background-color: $white;
                            border-radius: 5px;
                            color: $primary;
                        }
                        &.accueil.active {
                            background-color: initial;
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}

.stuck #cotiNav {
    @media (min-width: 992px) {
        padding: 5px 0;
        background: #000c;
        .navbar-brand {
            img {
                height: 48px;
            }
        }
    }
}
