/* ACTIVATION OPTIONS */
$enable-gradients: true;
$enable-negative-margins: true;
$enable-responsive-font-sizes: true;

/* GRID */
$grid-breakpoints: (
  xs: 0,
  xx: 420px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

/* COLORS */
$min-contrast-ratio: 2.0;

$body-bg: #eae4da;

$dark:    #222222;
$light:   #eeeeee;
$primary: #f26f21;
$warning: #ff9933;

/* FONT */
$headings-font-family: "Raleway", sans-serif;
$headings-font-weight: 900;

$font-family-base: "Roboto", sans-serif;
$font-size-base: 1rem;
$font-weight-normal: 300;
$font-weight-bold: 700;
$font-weight-bolder: 700;

$lead-font-size: $font-size-base * 1.5;

/* LIENS */
$link-decoration: none;

/* BUTTON */
$btn-border-width: 0;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$btn-font-family: "Raleway", sans-serif;
$btn-font-weight: 900;
