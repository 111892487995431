/* VARIABLES */
@import "variables";

/* BOOTSTRAP */
@import "~bootstrap/scss/bootstrap";

/* FONTS */
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,900;1,900&family=Roboto:ital,wght@0,300;0,700;1,300;1,700&display=swap");

/* PLUGINS */
@import "plugins/animate.css";
@import "plugins/divers.scss";
@import "plugins/flash_msg.scss";
@import "plugins/fontsize.css";
@import "plugins/g_translate.css";
@import "plugins/img_gal.scss";
@import "plugins/navbar.scss";
@import "plugins/partners_slider.scss";
@import "plugins/svg-icons.scss";
@import "plugins/tinymce.scss";

/* 
* COTI STYLE
*/

body {
  background-image: url("/images/bg-textures/cream-pixels.png");
}

.hiding {
  visibility: visible;
  @media (min-width: 992px) {
    visibility: hidden;
  }
}

a {
  transition: all 400ms;
  &:hover,
  &:focus {
    color: #bc4d0b;
  }
}

header.header {
  min-height: 99px;
}

h1, .h1,
h2, .h2,
h3, .H3,
h4, h5, h6 {
  text-transform: uppercase;
}

hr {
  margin: 1rem auto;
  max-width: 200px;
  border: .125rem solid $secondary;
  opacity: 1;
  @media (min-width: 992px) {
    max-width: 300px;
  }
}

hr.colored {
  border-color: $warning;
}

/* CONTENT */
.page-section {
  padding: 75px 0;
  @media (min-width: 992px) {
    padding: 100px 0;
  }
}

.stars {
  font-size: 4rem;
  line-height: 0.5;
  vertical-align: sub;
}

/* CAROUSEL */
.carousel-control-prev,
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next,
.carousel-control-next:hover,
.carousel-control-next:focus {
  background-image: none;
}

.carousel-control-prev {
  justify-content: flex-start;
  padding-left: 3%;
}

.carousel-control-next {
  justify-content: flex-end;
  padding-right: 3%;
}

/* FOOTER */
footer {
  background-attachment: fixed;
  background-image: url("/images/bg4.jpg");
  background-size: cover;
  color: $light;
  text-shadow: 0 1px 3px #000;
  a {
    color: $light;
    &:hover {
      color: #fff;
    }
  }
}

/* BOUTON */
.btn {
  text-transform: uppercase;
}

.btn-warning {
  color: #fff;
  &:focus,
  &:hover {
    color: #fff;
  }
}

.btn-full-width {
  width: 100%;
  padding: 50px 0;
  background-color: $dark;
  color: #fff;
  font-size: 1.625rem;
  transition: all 300ms;
  &:focus {
    color: #fff;
  }
  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

/* COLOR */
.text-primary {
  color: $primary !important;
}

a.text-primary {
  color: $primary !important;
  &:hover,
  &:focus {
    color: #bc4d0b !important;
  }
}

.bg-inverse {
  background-color: $dark !important;
}

/* COTI ADD */
.nav_icon {
  height: 18px;
  transition: all 0.5s;
  fill: #fff;
  &:hover {
    fill: rgba(255, 255, 255, 0.65);
  }
}

.nav_icon_dropdown {
  height: 18px;
  transition: all 0.5s;
  fill: $dark;
}

.nav-pills .nav-link {
  margin: 2px;
  background-color: #faebd7;
  font-weight: bold;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: $primary;
  color: #fff;
}

.btn-resa {
  padding: 0.5rem;
  background-color: #38c172;
  border-radius: 50%;
  font-size: 0.7875rem;
  line-height: 1;
}

.prestForm label {
  display: block;
  margin-bottom: 0.25rem;
}

/* GO TOP */
.scrollToTopBtn {
  position: fixed;
  bottom: 2rem;
  right: 0;
  height: 64px;
  width: 60px;
  background-image: url("/images/top.png");
  &:hover {
    background-image: url("/images/top-hover.png");
  }
  transform: translateX(100px);
  transition: all 400ms ease-in-out;
  z-index: 1050;
}
.showScrollBtn {
  transform: translateX(0);
}

/* DIVERS */
.mw165px {
  max-width: 165px;
}
