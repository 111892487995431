/* FORM */
.form-control[readonly] {
    background: #fff !important;
}

/* ACTU */
.date-actu {
    position: absolute;
    width: 68px;
    padding: 2px 0 16px;
    background: url('/images/bg-date-actu.png') center top no-repeat;
    font-size: 1rem;
    line-height: .95;
    text-align: center;
}

/* SOCIAL */
.facebook {
    color: #3b5998;
}
.bg-facebook {
    background-color: #3b5998;
    &:hover {
        background-color: darken(#3b5998, 10%);
    }
}

.google {
    color: #f1961d;
}
.bg-google {
    background-color: #f1961d;
    &:hover {
        background-color: darken(#f1961d, 10%);
    }
}


.instagram {
    color: #f46f30;
}
.bg-instagram {
    background-color: #f46f30;
    &:hover {
        background-color: darken(#f46f30, 10%);
    }
}

.twitter {
    color: #1da1f2;
}
.bg-twitter {
    background-color: #1da1f2;
    &:hover {
        background-color: darken(#1da1f2, 10%);
    }
}

.youtube {
    color: #f00;
}
.bg-youtube {
    background-color: #f00;
    &:hover {
        background-color: darken(#f00, 10%);
    }
}
