.coti-grid-img {
    width: 100%;
    height: 18vh;
    object-fit: cover;
    transition: 400ms;
}
.coti-grid-img:hover {
    transform: scale(1.05);
}

.coti-grid-accueil {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}
