#google_translate_element {
    min-width: 48px;
}

#google_translate_element,
.goog-te-gadget,
.goog-te-gadget-simple {
    max-height: 42px;
}

.goog-te-gadget img {
    display: none !important;
}

.goog-te-gadget-simple {
    padding: 0 !important;
    background-color: transparent !important;
    border: 0 !important;
}

.goog-te-gadget-simple:before {
    margin-top: 6px;
    display: block;
    color: #6c757d;
    content: '\f1ab';
    font-family: 'Font Awesome 5 Free';
    font-size: 1.6rem;
    font-weight: 900;
}

.goog-te-gadget-simple .goog-te-menu-value span {
    display: none;
}

html.translated-ltr #cotiNav {
    padding-top: 39px;
}

@media (min-width: 992px) {
    .goog-te-gadget-simple:before {
        margin-left: 8px;
        padding-left: 4px;
        padding-right: 4px;
    }
}