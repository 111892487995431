.icon {
  display: inline-block;
  fill: currentColor;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  vertical-align: -0.2em;
}
