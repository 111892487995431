.flash_message {
    position: absolute;
    top: -500px;
    left: 50%;
    margin: 0 auto;
    animation-name: alerte;
    animation-duration: 12s;
    box-shadow: 0 12px 25px #000;
    z-index: 9999;
    @media (min-width: 240px) {
        width: 240px;
        margin-left: -120px;
    }
    @media (min-width: 420px) {
        width: 400px;
        margin-left: -200px;
    }
    @media (min-width: 640px) {
        width: 600px;
        margin-left: -300px;
    }
}

@keyframes alerte {
    0% {
    }
    10% {
        top: 5rem;
    }
    85% {
        top: 5rem;
    }
    100% {
        top: -500px;
    }
}
