.slick-slide {
    display: none;
    float: left;
    margin: 0 4px;
    height: 100%;
    min-height: 1px;
    img {
        display: block;
        width: 100%;
    }
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    .slick-list,
    .slick-track {
        transform: translate3d(0, 0, 0);
    }
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
        outline: 0;
    }
    &.dragging {
        cursor: grabbing;
    }
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    &:after {
        display: table;
        clear: both;
        content: '';
    }
    &:before {
        display: table;
        content: '';
    }
}

.slick-loading {
    .slick-track {
        visibility: hidden;
    }
    .slick-slide {
        visibility: hidden;
    }
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}