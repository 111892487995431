.cotimg25,
.cotimg33,
.cotimg50,
.cotimg75,
.cotimg100,
.cotimg25left,
.cotimg33left,
.cotimg50left,
.cotimg25right,
.cotimg33right,
.cotimg50right {
    margin: 0 2px 4px;
    width: 100% !important;
    height: auto;
    border-radius: 4px;
}

.cotimg25,
.cotimg33,
.cotimg50,
.cotimg75 {
    padding: 0 2px;
}

.cotimg50center,
.cotimg75center {
    display: block;
    clear: both;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 75% !important;
    height: auto;
}

@media (min-width: 361px) {
    .cotimg25left,
    .cotimg33left,
    .cotimg50left {
        float: left;
        margin-right: 6px;
    }

    .cotimg25right,
    .cotimg33right,
    .cotimg50right {
        float: right;
        margin-left: 6px;
    }

    .cotimg25left,
    .cotimg25right,
    .cotimg33left,
    .cotimg33right,
    .cotimg50left,
    .cotimg50right {
        width: 45% !important;
    }

    .cotimg25,
    .cotimg33 {
        margin-right: auto !important;
        margin-left: auto !important;
        width: 50% !important;
    }

    .cotimg75 {
        margin-right: auto !important;
        margin-left: auto !important;
        width: 90% !important;
    }
}

@media (min-width: 480px) {
    .cotimg33 {
        width: 33% !important;
    }

    .cotimg25left,
    .cotimg25right {
        width: 42% !important;
    }

    .cotimg50 {
        width: 50% !important;
    }
}

@media (min-width: 576px) {
    .cotimg25left,
    .cotimg25right {
        width: 33% !important;
    }

    .cotimg33left,
    .cotimg33right,
    .cotimg50left,
    .cotimg50center,
    .cotimg50right {
        width: 50% !important;
    }

    .cotimg75 {
        width: 80% !important;
    }
}

@media (min-width: 768px) {
    .cotimg25left,
    .cotimg33left,
    .cotimg50left {
        margin-right: 12px;
    }

    .cotimg25right,
    .cotimg33right,
    .cotimg50right {
        margin-left: 12px;
    }

    .cotimg25,
    .cotimg25left,
    .cotimg25right {
        width: 25% !important;
    }

    .cotimg33,
    .cotimg33left,
    .cotimg33right {
        width: 33% !important;
    }

    .cotimg75 {
        width: 75% !important;
    }
}

@media (min-width: 1200px) {
    .cotimg25left,
    .cotimg33left,
    .cotimg50left {
        margin-right: 16px;
    }

    .cotimg25right,
    .cotimg33right,
    .cotimg50right {
        margin-left: 16px;
    }
}

/* FIGURE */
figure.image {
    display: inline-block;
    margin-top: .5rem;
}

figure.align-left {
    float: left;
}

figure.align-right {
    float: right;
}

figure.image figcaption {
    margin-bottom: .5rem;
    color: #555;
    font-size: .85rem;
    font-style: italic;
    text-align: center;
}
